import http from "@/utils/request";

// 获取列表
export function getList(data) {
    return http({
        // url: '/app/storeCheck/findResultList.do',
        url: 'cts/ctsApi.nd',
        method: 'post',
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    })
}

export function getMatkl(data) {
    return http({
        method: "post",
        url: "comm/queryMatklCode.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data,
    });
}

// pms/pmsApi/policyQuerySettlementSheet/export.nd


export function getExport(data) {
    return http({
        url: 'pms/pmsApi/policyQuerySettlementSheet/export.nd',
        method: 'post',
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data:data,
        responseType: 'blob'
    })
}