import Util from "@/utils/utils";
import { getList,getMatkl, getExport } from "./api";
import { getResuListFile } from "@/views/order/marketActivityResult/api";

export default {
  name: "inspection",
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/rewardList",
          name: "/rewardList",
          title: "奖励明细"
        }
      ],
      hideOrShowSize: true,
      columns: [
        {
          label: "序号",
          prop: "xuhao",
          width: 80
        },
        {
          label: "结算单号",
          prop: "clearingSheetNo",
          width: 200
        },
        {
          label: "售达方名称",
          prop: "customerNameSp",
          width: 220
        },
        {
          label: "售达方编码",
          prop: "customerCodeSp",
          width: 220
        },
        {
          label: "分销商名称",
          prop: "sxdName",
          width: 200
        },
        {
          label: "分销商编码",
          prop: "sxdCode",
          width: 200
        },
        {
          label: "产品分组名称",
          prop: "productName",
          width: 220
        },
        {
          label: "奖励金额",
          prop: "confirmAmount",
          width: 200
        },
        {
          label: "销售组织名称",
          prop: "companyName",
          width: 220
        },
        {
          label: "销售组织编码",
          prop: "companyCode",
          width: 200
        },
        {
          label: "应付时间",
          prop: "copeDate",
          width: 200
        },
        {
          label: "政策编码",
          prop: "policyNo",
          width: 200
        },
        {
          label: "政策名称",
          prop: "policyName",
          width: 200
        },
        {
          label: "操作",
          slot: "btnClick",
          ellipsis: true,
          fixed: "right",
          width: 150
        }
      ],
      tableData: [],
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      expLoading:false,
      ssearchList: {
        "clearingSheetNo": "",
        "clearingState": "",
        "companyCode": "",
        "companyName": "",
        "startCopeDate": "",
        "customerCodeSp": "",
        "customerNameSp": "",
        "endCopeDate": "",
        "pageNum": 1,
        "pageSize": 10,
        "policyName": "",
        "policyNo": "",
        "productCode": "",
        "sendFlag": "",
        "sxdCode": "",
        "sxdName": "",
        "serviceCode": "policyQuerySettlementSheet"
      },
      ssearchListNew: {
        "clearingSheetNo": "",
        "clearingState": "",
        "companyCode": "",
        "companyName": "",
        "startCopeDate": "",
        "customerCodeSp": "",
        "customerNameSp": "",
        "endCopeDate": "",
        "pageNum": 1,
        "pageSize": 10,
        "policyName": "",
        "policyNo": "",
        "productCode": "",
        "sendFlag": "",
        "sxdCode": "",
        "sxdName": "",
        "serviceCode": "policyQuerySettlementSheet"
      },
      searchNumber: 0,
      hideshow: false,
      isLoading: false,
      MatklList: [],
      tableLoading: false
    };
  },
  mounted() {
    this.getMa();
    this.tablelist();

  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#ffffff");
  },
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
  methods: {
    // wuliaoziu
    getMa() {
      let data = {
        orgId: ""
      };
      getMatkl(data).then(res => {
        this.MatklList = res.data.list;
      });
    },
    fun_date(num) {
      var date1 = new Date();
      var yuefen =
        date1.getMonth() + 1 < 10
          ? "0" + Number(date1.getMonth() + 1)
          : date1.getMonth();
      var ri =
        date1.getDate() + 1 < 10 ? "0" + date1.getDate() : date1.getDate();
      var time1 = date1.getFullYear() + "-" + yuefen + "-" + ri;
      this.ssearchList.endTime = time1;

      var date2 = new Date(date1);
      date2.setDate(date1.getDate() + num);
      var yu =
        date2.getMonth() + 1 < 10
          ? "0" + Number(date2.getMonth() + 1)
          : date2.getMonth();
      var riq =
        date2.getDate() + 1 < 10 ? "0" + date2.getDate() : date2.getDate();
      var time2 = date2.getFullYear() + "-" + yu + "-" + riq;
      this.ssearchList.startTime = time2;
    },
    exportClick() {
      let data = JSON.parse(JSON.stringify(this.ssearchList));
      data.pageSize = 200000;
      data.pageNum = 1;
      this.expLoading = true;
      getExport(data)
        .then(res => {
          this.isLoading = false;
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "奖励列表.xls");
            })
            .finally(() => {
              this.expLoading = false;
            });
        })
        .finally(res => {
          this.expLoading = false;
        });
    },
    loadMore() {
      this.hideshow = !this.hideshow;
    },
    // inputSearch(data) {
    //     this.ssearchList = data[1].paramList;
    //     this.searchzz = true;
    //     this.tablelist();
    // },
    pageSearch(pager) {
      this.ssearchList.pageNum = pager.pageNo;
      this.ssearchList.pageSize = pager.pageSize;
      this.ssearchListNew  ={...this.ssearchListNew,
        pageNum:pager.pageNo,
        pageSize: pager.pageSize
      }
      this.tablelist();
    },

    tablelist() {
      let data = this.ssearchListNew;
      this.tableLoading = true;
      getList(data).then(res => {
        if (res.data.code == 200) {
          this.tableData = res.data.data.records;
          if (res.data.data.records) {
            this.searchNumber = res.data.data.total;
            this.pager.count = res.data.data.total;

          }
          if (this.tableData && this.tableData.length > 0) {
            this.tableData.forEach((item, index) => {
              item.xuhao = index + 1;
              // item.proportion = (item.proportion)+'%'
            });
          }
          this.tableLoading = false;
        }else if(res.data.code == 'fail'){
          this.$message.warning(res.data.msg);
          this.tableLoading = false;
        } else {
          this.tableData = []
          this.searchNumber = 0
          this.pager.count = 0
          this.$message.warning(res.data.message);
          this.tableLoading = false;
        }

      });
    },
    // lastpagenum(totalPages) {
    //     let data = JSON.parse(JSON.stringify(this.ssearchList));
    //     data.pageNo = totalPages;
    //     getList(data).then(res => {
    //         if (res.data.list) {
    //             this.searchNumber = this.searchNumber + (res.data.list == null ? 0 : res.data.list.length);
    //         }
    //     })
    // },
    hideOrShow(data) {
      this.hideOrShowSize = data;
    },
    // 重置
    reset() {
      this.ssearchList = {
        "clearingSheetNo": "",
        "clearingState": "",
        "companyCode": "",
        "companyName": "",
        "startCopeDate": "",
        "customerCodeSp": "",
        "customerNameSp": "",
        "endCopeDate": "",
        "pageNum": 1,
        "pageSize": 10,
        "policyName": "",
        "policyNo": "",
        "productCode": "",
        "sendFlag": "",
        "sxdCode": "",
        "sxdName": "",
        "serviceCode": "policyQuerySettlementSheet"
      };
      this.ssearchListNew  ={...this.ssearchListNew,... this.ssearchList}
      this.tablelist();
    },

    // 分页
    pagesiezes(data, pageSize) {
      this.ssearchList.pageNum = data;
      this.ssearchList.pageSize = pageSize;
      this.ssearchListNew  ={...this.ssearchListNew,
        pageNum:data,
        pageSize:pageSize
      }
      this.tablelist();
    },


    searchClick() {
      this.ssearchList.pageNum = 1
      this.ssearchListNew  ={...this.ssearchListNew,... this.ssearchList}
      this.tablelist();
    },
    // 查看详情
    toDetail(row) {
      let routeUrl = this.$router.resolve({
        path: "/rewardList/rewardView",
        query: {
          clearingSheetNo:row.clearingSheetNo,
          customerNameSp:row.customerNameSp,
          customerCodeSp:row.customerCodeSp,
          productName:row.productName,
          confirmAmount:row.confirmAmount,
          companyName:row.companyName,
          copeDate:row.copeDate,
          policyNo:row.policyNo,
          policyName:row.policyName,
          sendFlag:row.sendFlag,
          contractNo:row.contractNo
        }
      });
      window.open(routeUrl.href, "_blank");
    }
  }
};
